function MetaDialog({
  show,
  setShow,
  isConnected,
  setIsConnected,
  lang,
  business_data,
  toast,
  getInstagramPage,
  setGetInstagramPage,
  getSettingAccount,
  getFacebookPage,
  setGetFacebookPage,
  maxPage,
  settingChatCenter
}) {
  const [instagramPages, setInstagramPages] = React.useState([]);
  const [tempInstagramPages, setTempInstagramPages] = React.useState([]);
  const [facebookPages, setFacebookPages] = React.useState([]);
  const [tempFacebookPages, setTempFacebookPages] = React.useState([]);
  const [metaUserId, setMetaUserId] = React.useState("");
  const [facebookPageCount, setFacebookPageCount] = React.useState(getFacebookPage.length);
  const [instagramPageCount, setInstagramPageCount] = React.useState(getInstagramPage.length);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  React.useEffect(() => {
    FB.getLoginStatus(function (response) {
      shappyMain.log("response", response);
      if (response.status == "connected") {
        setIsConnected(true);
        setMetaUserId(response.authResponse.userID);
        onMetaConnect(
          response.authResponse.userID,
          response.authResponse.accessToken,
          true
        );
      }
    });
  }, []);

  const ConnectMeta = () => {
    FB.login(
      function (response) {
        if (response.status == "connected") {
          setIsConnected(true);
          setMetaUserId(response.authResponse.userID)
          onMetaConnect(
            response.authResponse.userID,
            response.authResponse.accessToken
          );
        }
      },
      {
        scope:
          "pages_show_list,pages_messaging,pages_read_engagement,business_management,instagram_basic,instagram_manage_messages,pages_manage_metadata",
      }
    );
  };

  const checkMetaConnected = async (facebook_page, instagram_account) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url:
          backend +
          "setting/" +
          business_data.uid +
          "/" +
          business_data.app_id +
          "/checkMetaConnected",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
          "X-VC-Access-Token": business_data.access,
          Authorization: "Bearer " + business_data.token,
        },
        data: JSON.stringify({
          facebook_page: facebook_page,
          instagram_account: instagram_account,
        }),
      }).then((res) => {
        if (!res.data.result) {
          resolve(res.data.data);
        } else {
          reject();
        }
      });
    });
  }


  const onMetaConnect = async (userID, accessToken, firstRender) => {
    setIsLoading(true);
    FB.api(
      "/" +
        userID +
        "/accounts?fields=name,username,access_token,instagram_business_account&access_token=" +
        accessToken,
      async function (res) {
        let pageList = [];
        let facebookPageList = [];
        let connected;

        const promises = _.map(res.data, function (value, key) {
          return new Promise((resolve, reject) => {
            value.userAccessToken = accessToken;
            connected = _.find(getFacebookPage, { page_id: value.id });
            value.connected = connected ? true : false;
            facebookPageList[key] = _.cloneDeep(value);
            if (value.instagram_business_account) {
              FB.api(
                "/" +
                  value.instagram_business_account.id +
                  "?fields=id,username&access_token=" +
                  value.access_token,
                function (instagramData) {
                  value.instagram_id = instagramData.id;
                  value.username = instagramData.username;
                  value.userAccessToken = accessToken;
                  connected = _.find(getInstagramPage, {
                    account_id: value.instagram_id,
                  });
                  console.log("connected", connected);
                  value.connected = connected ? true : false;
                  pageList[key] = _.cloneDeep(value);
                  resolve();
                }
              );
            } else {
              resolve();
            }
          });
        });

        await Promise.all(promises);
        if (!firstRender) {
          getSettingAccount();
        }
        let filteredPageList = pageList.filter((item) => item);
        let { facebook_page, instagram_account } = await checkMetaConnected(facebookPageList, filteredPageList);
        getFacebookPage.forEach((item) => {
          if (!facebook_page.find((page) => page.id === item.page_id)) {
            facebook_page.push({
              id: item.page_id,
              name: item.title,
              access_token: item.page_token,
              connected: true,
              not_have_permission: true,
            });
          }
        });
        getInstagramPage.forEach((item) => {
          if (
            !instagram_account.find(
              (page) => page.instagram_id === item.account_id
            )
          ) {
            instagram_account.push({
              id: item.facebook_page_id,
              instagram_id: item.account_id,
              username: item.title,
              access_token: item.account_token,
              connected: true,
              not_have_permission: true,
            });
          }
        });
        setInstagramPages(instagram_account);
        setFacebookPages(facebook_page);
        setTempInstagramPages(instagram_account);
        setTempFacebookPages(facebook_page);
        setIsLoading(false);
      }
    );
  };

  const submitMetaSetting = () => {
    setIsUpdating(true);
    let facebookPageList = _.cloneDeep(facebookPages);
    let instagramAccountList = _.cloneDeep(instagramPages);
    let facebookPage = facebookPageList.filter((item) => item.connected);
    let instagramAccount = instagramAccountList.filter(
      (item) => item.connected
    );

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url:
          backend +
          "setting/" +
          business_data.uid +
          "/" +
          business_data.app_id +
          "/meta-api",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
          "X-VC-Access-Token": business_data.access,
          Authorization: "Bearer " + business_data.token,
        },
        data: JSON.stringify({
          facebook_page: facebookPage,
          instagram_account: instagramAccount,
          facebookUserId: metaUserId,
        }),
      }).then((res) => {
        if (!res.data.result) {
          let updatedFacebookPages = facebookPageList.map((item) => ({
            ...item,
            connected: !!_.find(res.data.data.facebook_page, {
              page_id: item.id,
            }),
          })).filter((item) => item.connected || !item.not_have_permission);

          let updatedInstagramPages = instagramAccountList.map((item) => ({
            ...item,
            connected: !!_.find(res.data.data.instagram_account, {
              account_id: item.instagram_id,
            }),
          })).filter((item) => item.connected || !item.not_have_permission);

          settingChatCenter(res.data.data.facebook_page, res.data.data.instagram_account);
          setFacebookPages(updatedFacebookPages);
          setInstagramPages(updatedInstagramPages);
          setTempFacebookPages(updatedFacebookPages);
          setTempInstagramPages(updatedInstagramPages);
          setGetFacebookPage(res.data.data.facebook_page);
          setGetInstagramPage(res.data.data.instagram_account);
          setIsUpdating(false);
          setShow(false);
          toast.success(lang.__save_successfully);
          resolve();
        } else {
          setIsUpdating(false);
          toast.error(lang.__save_unsuccessfully);
          reject();
        }
      }).catch(() => {
        setIsUpdating(false);
        toast.error(lang.__save_unsuccessfully);
        reject();
      })
      ;
    });
  };

  const handleCancel = () => {
    setFacebookPages(tempFacebookPages);
    setInstagramPages(tempInstagramPages);
    setShow(false);
    console.log("cancel");
  };

  const checkPage = (page, type) => {
    if (type === "facebook") {
      let facebookPage = _.cloneDeep(facebookPages);
      const index = _.findIndex(facebookPage, (item) => {
        return item.id == page.id;
      });
      facebookPage[index].connected = !facebookPage[index].connected;
      if (facebookPage[index].connected) {
        if (facebookPageCount >= maxPage) {
          toast.error(lang.__account_exceed_limit);
          return;
        }
        setFacebookPageCount(facebookPageCount + 1);
      } else {
        setFacebookPageCount(facebookPageCount - 1);
      }
      setFacebookPages(facebookPage);
    } else if (type === "instagram") {
      let instagramPage = _.cloneDeep(instagramPages);
      const index = _.findIndex(instagramPage, (item) => {
        return item.instagram_id == page.instagram_id;
      });
      instagramPage[index].connected = !instagramPage[index].connected;
      if (instagramPage[index].connected) {
        if (instagramPageCount >= maxPage) {
          toast.error(lang.__account_exceed_limit);
          return;
        }
        setInstagramPageCount(instagramPageCount + 1);
      } else {
        setInstagramPageCount(instagramPageCount - 1);
      }
      setInstagramPages(instagramPage);
    }
  };

  return (
    <div>
      <div
        className={`modal ${show ? "fade in" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div class="sp-label">
                {isConnected ? lang.__select_account : lang.__connect_meta}
              </div>
            </div>
            <div className="modal-body" style={{ maxHeight: "80vh", overflow: "auto", scrollBehavior: "smooth" }}>
              {isConnected ? (
                isLoading ? (
                  <div class="sp-text-help" style={{ textAlign: "center" }}>
                    {lang.__loading}
                  </div>
                ) : (
                  <div
                    class="body -scrollable sp-list facebook-page-list"
                    ng-show="isConnected"
                  >
                    {instagramPages[0] || facebookPages[0] ? null : (
                      <div
                        class="sp-text-help"
                        style={{ textAlign: "center", height: 30 }}
                      >
                        {lang.__account_not_found}
                      </div>
                    )}
                    {facebookPages[0] &&
                      facebookPages.map((page, index) => {
                        return (
                          <div
                            class="sp-row item"
                            style={{ cursor: page.warning ? "not-allowed" : "pointer" }}
                            onClick={() => {
                              !page.warning && checkPage(page, "facebook")}}
                          >
                            <img
                              src="../public/img/facebook.png"
                              width={20}
                              height={20}
                              style={{ marginRight: 10 }}
                            />
                            <div class="sp-col">
                              <div class="sp-row">
                                <div class="sp-text -ellipsis -align-middle" style={{ color: page.warning && "#AEBAD0"}}>
                                  {page.name}
                                </div>
                              </div>
                              <div class="sp-row">
                                <p class="sp-text-help -ellipsis">{page.id}</p>
                              </div>
                            </div>
                            {page.warning ? (
                                <span class="sp-text-help" style={{ fontSize: 10, textAlign: "right", width: "100%", paddingRight: 10, marginBottom: 'unset' }}>
                                  {lang.__already_connect_rmp}
                                </span>
                              ) : null}
                            <div class="sp-col -wrap -align-right">
                              <div class="sp-checkbox sp-row">
                                <input
                                  type="checkbox"
                                  class="sp-input sp-col -wrap"
                                  style={{ cursor: page.warning ? "not-allowed" : "pointer" }}
                                  disabled={page.warning}
                                  checked={page.connected}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {instagramPages[0] &&
                      instagramPages.map((page, index) => {
                        return (
                          <div
                            class="sp-row item"
                            style={{
                              cursor: page.warning ? "not-allowed" : "pointer",
                            }}
                            onClick={() => {
                              !page.warning && checkPage(page, "instagram");
                            }}
                          >
                            <img
                              src="../public/img/instagram_icon.png"
                              width={20}
                              height={20}
                              style={{ marginRight: 10 }}
                            />
                            <div class="sp-col">
                              <div class="sp-row">
                                <div class="sp-text -ellipsis -align-middle" style={{ color: page.warning && "#AEBAD0"}}>
                                  {page.username}
                                </div>
                              </div>
                              <div class="sp-row">
                                <p class="sp-text-help -ellipsis">
                                  {page.instagram_id}
                                </p>
                              </div>
                            </div>
                            {page.warning ? (
                                <span class="sp-text-help" style={{ fontSize: 10, textAlign: "right", width: "100%", paddingRight: 10, marginBottom: 'unset' }}>
                                  {lang.__already_connect_rmp}
                                </span>
                              ) : null}
                            <div class="sp-col -wrap -align-right">
                              <div class="sp-checkbox sp-row">
                                <input
                                  type="checkbox"
                                  class="sp-input sp-col -wrap"
                                  style={{
                                    cursor: page.warning
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                  disabled={page.warning}
                                  checked={page.connected}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )
              ) : (
                <div class="body -scrollable" style={{ height: "26vh" }}>
                  <div class="sp-placeholder">
                    <div
                      class="sp-text-help"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: "0 100px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          class="sp-btn facebook-btn"
                          onClick={() => ConnectMeta()}
                        >
                          <img
                            class="image"
                            src="../public/img/facebook-white.png"
                          />
                          <span>{lang.__facebook_continue}</span>
                        </button>
                      </div>

                      <div style={{ marginTop: 20 }}>
                        {lang.__facebook_connect_description}
                      </div>
                      <div>
                        <a
                          href="https://help.readyplanet.com/rchat/chat-center/connect-with-meta"
                          target="_blank"
                        >
                          <span class="sp-link">{lang.__meta_manual_link}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <div class="sp-row">
                {isConnected ? (
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span class="sp-link" onClick={() => ConnectMeta()}>
                      {lang.__connect_meta_again}
                    </span>
                  </div>
                ) : null}
                <div style={{ flexGrow: isConnected ? 0 : 1 }}>
                  <ButtonCancel
                    style={{
                      fontSize: 14,
                      marginRight: 0,
                      display: isConnected && "flex",
                      alignItems: "center",
                    }}
                    onClick={handleCancel}
                  >
                    {lang.__cancel}
                  </ButtonCancel>
                </div>
                {isConnected ? (
                  <div style={{ flexGrow: isConnected ? 0 : 1 }}>
                    <button
                      class="sp-btn -lightBlue"
                      style={{
                        fontSize: 14,
                        marginLeft: 10,
                      }}
                      onClick={submitMetaSetting}
                    >
                      {isUpdating ? (
                        <i class="icon icon-circle-notch animate-spin"></i>
                      ) : (
                        lang.__analytic_apply
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${show ? "fade in" : ""}`}
        style={{ display: show ? "" : "none" }}
      ></div>
    </div>
  );
}
